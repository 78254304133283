import { Injectable } from '@angular/core';
import { env } from './../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  /**
   * Returns environment config of application
   */
  getEnvironment() {
    return env;
  }

  /**
  * Indicates whether the apps is running in production mode
  *
  * @return {*}  {boolean}
  */
  isProd() {
    return env.production;
  }

  /**
   * Returns app's version
   */
  getVersion(): string {
    return env.appVersion;
  }

  /**
   * Returns the server's host url
   */
  getAPIUrl(): string {
    return env?.apiBaseUrl ?? '';
  }

  /**
   * Returns configuration for auth client and secret
   */
  getAuthSettings() {
    return env.auth;
  }

  /**
   * Returns the application id
   * */
  public get AppId(): string {
    return env.auth.applicationId;
  }


}
