import { IEnvironmentSettings } from "./model";
import { version } from './../../package.json'

export const env: IEnvironmentSettings = {
    name: "dev",
    production: false,
    appVersion: `${version}-dev`,
    apiBaseUrl: 'https://sandbox.nexusforge.app',
    auth: {
        accessTokenKey: 'DoPS3ZrQjM',
        refreshTokenKey: 'nmlP8PW2nb',
        applicationId: "demoappkey"
    },
    formsAppUrl: "https://formsandbox.nexusforge.app"
}
